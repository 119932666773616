import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {Stack} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {GridEditModes} from "@mui/x-data-grid";
import {proccesCellUpdate} from "./NewAccountingentryView";
import Grid from "@mui/material/Grid";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const ui = {
    fields: {
        accountingentrydetailid : {name: 'accountingentrydetailid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: true, visible: false, editable: false},
        accountingaccountid : {name: 'accountingaccountid', label:'Cuenta' , typeName: 'es.rbm.model.jpa.Accountingaccount', keyField: 'account', descriptiveField: 'description', flex: 1.5, optional: false, proccesCellUpdate},
        description : {name: 'description', label: 'Descripción',typeName:'String', align: 'center', optional: false, flex: 1.5},
        concept : {name: 'concept', label: 'Concepto',typeName:'String', align: 'center', optional: false, flex: 1.5},
        debit : {name: 'debit', label:'Debe' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter},
        credit : {name: 'credit', label:'Haber' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter},
    },
    sort: {
        field: 'accountingentrydetailid',
        sort: 'desc'
    },
    keyComponent: 'saleAccountingentrydetails',
}

export default function AccountingentryDialog(props) {
    const {accountingentryid} = props;
    const [state, setState] = React.useState({records: [], credit: 0, debit: 0});
    const {credit, debit, records} = state;
    const balance = Math.round((debit || 0) * 100) / 100 - Math.round((credit || 0) * 100) / 100;
    const {entrynumber} = accountingentryid;

    useEffect(() =>{
        refresh()
    }, [accountingentryid])

    const refresh = () => {
        setState({...state, records: []})
        if (accountingentryid) {
            const filters = {
                entity : {name: 'accountingentrydetail'},
                fields: {
                    accountingentryid : {
                        name: 'accountingentryid',
                        operator: OPERATORS.OPERATOR_EQUAL,
                        value: accountingentryid
                    }
                },
            }
            searchByCriteria(filters, result =>{
                if (result) {
                    let debit = result.reduce((l, c) => l + c.debit, 0);
                    let credit = result.reduce((l, c) => l + c.credit, 0);
                    setState({...state, records: result, debit, credit})
                }
            });
        }
    }
    
    const onDelete = (accountingentrydetailid) => {
        doDelete('rest/accountingentrydetail/delete/' + accountingentrydetailid.accountingentrydetailid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const {records} = state;
        const record = records.filter(r => r.accountingentrydetailid === e.accountingentrydetailid);
        if (record.length) {
            doPutRest('accountingentrydetail', e, () => refresh());
        } else {
            delete e.accountingentrydetailid
            doPutRest('accountingentrydetail', e, () => refresh());
        }
    }

    const addNewLine = () => {
        const {records, detailsForm} = state;
        let newRecord = {accountingentrydetailid: 0, accountingentryid: accountingentryid};
        let validRecordConcept = records.filter(r => r.concept != null);
        if (validRecordConcept && validRecordConcept.length) {
            newRecord.concept = validRecordConcept[0].concept;
        }
        records.push(newRecord)
        setState({...state, records: [...records], edit: true})
    }

    return(
        <Box sx={{m: 2, alignItems: 'left', width: '100%' }}>

            <Button size={'small'} variant="contained" onClick={addNewLine} startIcon={<AddIcon />} sx={{m: 1}}>
                Añadir línea
            </Button>
            <GridTable ui={ui} onDelete={onDelete}
                       editMode={GridEditModes.Row}
                       records={records} rowId={'accountingentrydetailid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '20vh', bgcolor: '#ffffff'}}
            />
            <Paper spacing={5} sx={{bgcolor: balance !== 0 ? '#ffabab' : '#ffffff', mt: 1, p: 1}}>
                <Grid container>
                    <Grid item md={1}>
                        {entrynumber ? '  Asiento nº ' + entrynumber : ''}
                    </Grid>
                    <Grid item md={11}>
                        <Grid container>
                            <Grid item md={8} align={'center'}>
                                    {balance !== 0 ? 'El asiento está descuadrado' : ''}
                            </Grid>
                            <Grid item md={4} align={'right'}>
                                {'Debe: ' + (Math.round(debit * 100) / 100) + '. Haber: ' + (Math.round(credit * 100) / 100)
                                    + '. Diferencia: ' + (Math.round(balance * 100) / 100) + '.'}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
