import * as React from 'react';
import AccountingentryDialog from "../Accounting/AccountingentryDialog";
import Grid from "@mui/material/Grid";
import PaymentduedateGrid from "../Accounting/PaymentduedateGrid";

export default function PurchaseAccountingDialog(props) {
    const {purchaseinvoiceid} = props;
    const [state, setState] = React.useState({});

    return(
        <Grid container>
            <Grid item md={12}>
                <AccountingentryDialog accountingentryid={purchaseinvoiceid.accountingentryid} />
            </Grid>
            <Grid item md={12}>
                <PaymentduedateGrid purchaseinvoiceid={purchaseinvoiceid} />
            </Grid>
        </Grid>

    )
}
