import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import AccountingentryDialog from "./AccountingentryDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import NewFertigation from "../../../Agro/Crud/Fertigation/NewFertigation/NewFertigation";
import NewAccountingentryView from "./NewAccountingentryView";

const getUiTab = (accountingentryid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Asiento contable", component: <EditObjectDialog objectid={accountingentryid} entityDefinition={accountingentryUI}/> },
                {label : "Detalles", component: <AccountingentryDialog accountingentryid={accountingentryid} entityDefinition={accountingentryUI}/> },
            ]}
    })
}

const getNewAccountingEntryComponent = (setOpenNewObject, openNewObject) =>{
    return <NewAccountingentryView setOpenNewObject={setOpenNewObject} openNewObject/>
}

export const accountingentryUI = {
    entity : {
        name: 'accountingentry',
        keyField: 'accountingentryid',
        label: 'Asiento contable',
        info: {typeName: 'es.rbm.model.jpa.Accountingentry'}
    },
    uiTable: {
        fields:{
            accountingentryid : {name: 'accountingentryid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            entrynumber : {name: 'entrynumber', label:'Nº asiento' , typeName: 'Number', editable: false},
            date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true},
            description : {name: 'description', label:'Descripción' , typeName: 'String', editable: true, align: 'center'},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center'},
        },
        keyComponent: 'accountingentryCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            accountingentryid : {name: 'accountingentryid', label:'#' , typeName: 'Number', editable: false, size: 2},
            entrynumber : {name: 'entrynumber', label:'Nº asiento' , typeName: 'Number', editable: false, size: 2},
            description : {name: 'description', label:'Descripción' , typeName: 'String', editable: true, align: 'center', size: 8},
            date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center'},
        }
    },
    onClickNewObject : {component : (setOpenNewObject, openNewObject) => getNewAccountingEntryComponent(setOpenNewObject, openNewObject)},
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            date_1: new Date(new Date().setDate(new Date().getDate() - 31))
        },
        fields: {
            entrynumber : {name: 'entrynumber',label: 'Nº asiento', typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            contractorid : {name: 'contractorid',label: 'Empresa', typeName: 'es.rbm.model.jpa.Contractor', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            date_1 : {name: 'date_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            date_2 : {name: 'date_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
        }
    },
    uiTab: (accountingentryid) => getUiTab(accountingentryid)
}

