import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "../../../../../../View/Form/GridTable";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";

const gridUI = {
    fields: {
        date : {name: 'date', label:'Fecha' , typeName: 'Date', optional: false, editable: true, flex: 0.5},
        duedate : {name: 'duedate', label:'Fecha vencimiento' , typeName: 'Date', optional: false, editable: true, flex: 0.5},
        paymentmethodid : {name: 'paymentmethodid', label:'Metodo de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', optional: false, editable: true, flex: 0.7},
        bankaccountid : {name: 'bankaccountid', label:'Cuenta banco' , typeName: 'es.rbm.model.jpa.Bankaccount', optional: false, editable: true},
        amount : {name: 'amount', label:'Importe' , typeName: 'Number', optional: false, editable: false, flex: 0.5},
        pending : {name: 'pending', label:'Pendiente' , typeName: 'Number', optional: false, editable: false, flex: 0.5},
        settle : {name: 'settle', label:'Liquidar' , typeName: 'Number', optional: false, editable: true, flex: 0.5},
    },
    sort: {
        field: 'date',
        sort: 'asc'
    },
    keyComponent: 'PaymentGrid',
}

const formUI = {
    fields:{
        date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true, size: 2, empty: true, optional: true},
        duedate : {name: 'duedate', label:'Fecha vencimiento' , typeName: 'Date', editable: true, size: 2, empty: true, optional: true },
        //valuedate : {name: 'duedate', label:'Fecha vencimiento' , typeName: 'Date', editable: true, size: 2},
        paymentmethodid : {name: 'paymentmethodid', label:'Metodo de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', optional: true, editable: true, size: 2},
        bankaccountid : {name: 'bankaccountid', label:'Cuenta banco' , typeName: 'es.rbm.model.jpa.Bankaccount', optional: true, editable: true, size: 2},
    },
    keyComponent: 'NewCultivationHarvestDialog'
}

export default function PaymentDialog(props) {
    const {closeDialog, paymentduedates} = props;
    const [state, setState] = React.useState({records: [], header: {}});
    const {records, header} = state;

    useEffect(() => {
        refresh();
    }, [paymentduedates])

    const refresh = () => {
        paymentduedates.forEach(r => {
            r.duedate = r.date;
            r.settle = r.pending;
        })
        setState({...state, records: [...paymentduedates]})
    }

    const onFormChangeChange = (record) => {
        const {records} = state;
        Object.keys(record).filter(k => record[k]).forEach(k => records.forEach(r => r[k] = record[k]));
        setState({...state, records: records, header: {...record}})
    }

    const onGridChange = (newRecord, oldRecord) => {
        const {records} = state;
        if (records.includes(oldRecord)) {
            let index = records.indexOf(oldRecord);
            records[index] = newRecord;
        }
        setState({...state, records: [...records]})
    }

    const generatePayment = () => {
        let request = {records: [...records]};
        doPost('accounting/generatePayment', request, result => {
           if (result) {
               closeDialog && closeDialog(true);
           }
       })
    }

    return(
        <Box sx={{p: 2, alignItems: 'left', width: '100%' }}>
            <Grid container>
                <Grid item md={11}>
                    <SingleRecordForm ui={formUI} records={header} sx={{mb: 2}} onChange={onFormChangeChange} disableAutoFocus />
                </Grid>
                <Grid item md={1}>
                    <SuccessButton text={'Liquidar'} fullWidth color={'secondary'} onClick={generatePayment} />
                </Grid>
            </Grid>
            <GridTable ui={gridUI} records={records} rowId={'paymentduedateid'}
                       density={'compact'} hideFooter={true}
                       sx={{height: '15vh', bgcolor: '#ffffff'}}
                       onChange={onGridChange}
            />
        </Box>
    )
}
