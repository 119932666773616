import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import {movementunitUI} from "../Movementunit/movementunitCrud";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {accountingAccountUI} from "../Accounting/accountingaccountCrud";


const ui = {
    fields:{
        bankaccountid : {name: 'bankaccountid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: true, visible: false, editable: false},
        bankaccount : {name: 'bankaccount', label:'Cuenta banco' , typeName: 'String', optional: false},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', optional: false},
        accountingaccountid : {name: 'accountingaccountid', label:'Cuenta contable' , typeName: 'es.rbm.model.jpa.Accountingaccount', optional: false, addObject: accountingAccountUI},
        iban : {name: 'iban', label:'IBAN' , typeName: 'String', optional: false},
    }
}
export default function NewBankaccountDialog(props) {
    const {open, setOpen, bankid} = props;
    const [bankaccountid, setBankaccountid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setBankaccountid({...record});
        setComplete(complete);
    }

    const addBankaccount = (bankaccountid, callback) => {
        const toSend = {...bankaccountid, bankid: {bankid: bankid.bankid}}
        doPost('rest/bankaccount/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const bankaccountid1 = props.bankaccountid;
        setBankaccountid(bankaccountid1 ? bankaccountid1 : {});
    }, [props.bankaccountid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addBankaccount(bankaccountid, () => setOpen(!open))
    };

    return(
        <Box sx={{m: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={bankaccountid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    );
}
