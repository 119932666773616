import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import PurchaseinvoiceDocumentView from "./PurchaseinvoiceDocumentView";
import {purchaseinvoicestatusUI} from "./purchaseinvoicestatusUI";
import DetailsPurchaseInvoiceDialogNew from "../Purchaseorder/DetailsPurchaseInvoiceDialogNew";
import PurchaseinvoiceExtractor from "../../../Purchase/PurchaseinvoiceExtractor";
import AccountingentryDialog from "../Accounting/AccountingentryDialog";
import PurchaseAccountingDialog from "./PurchaseAccountingDialog";

const getUiTab = (purchaseinvoiceid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Factura", component: <EditObjectDialog objectid={purchaseinvoiceid} entityDefinition={purchaseinvoiceUI}/> },
                {label : "Detalles", component: <DetailsPurchaseInvoiceDialogNew purchaseinvoiceid={purchaseinvoiceid}/>},
                {label : "Contabilidad/Tesorería", component: <PurchaseAccountingDialog purchaseinvoiceid={purchaseinvoiceid}/>},
                {label : "Documentos", component: <PurchaseinvoiceDocumentView purchaseinvoiceid={purchaseinvoiceid}/> },
            ]}
    })
}

const autoComplete = (record, field, value, callback) =>{
    if (field === 'contractorid') {
        const newRecord = {...record};
        const contractorid = record?.contractorid;
        newRecord.serie = contractorid?.seriepurchaseinvoice;
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}

export const providerUILocal = {
    entity : {
        name: 'provider',
        keyField: 'providerid',
        label: 'Proveedor',
        urlExcelImport: 'importer/importProviderFromExcel',
        info: {typeName: 'es.rbm.model.jpa.Provider'}
    },
    uiForm : {
        fields:{
            providerid : {name: 'providerid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            provider : {name: 'provider', label:'Nombre' , typeName: 'String' },
            idcardnumber : {name: 'idcardnumber', label:'DNI/CIF' , typeName: 'String', editable: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String',  multiline: true, rows: 5, size: 12},
        }
    },
}


export const purchaseinvoiceUI = {
    entity : {
        name: 'purchaseinvoice',
        keyField: 'purchaseinvoiceid',
        label: 'Factura de compra',
        colorValue: 'purchaseinvoicestatusid',
        info: {typeName: 'es.rbm.model.jpa.Purchaseinvoice'}
    },
    uiTable: {
        fields:{
            purchaseinvoiceid : {name: 'purchaseinvoiceid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            reference : {name: 'reference', label: 'Referencia',typeName:'String', optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa', typeName:'es.rbm.model.jpa.Contractor'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            providerid : {name: 'providerid', label: 'Proveedor',typeName:'es.rbm.model.jpa.Provider'},
            purchaseinvoicestatusid : {name: 'purchaseinvoicestatusid', label: 'Estado', typeName:'es.rbm.model.jpa.Purchaseinvoicestatus', align: 'center'},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', flex: 0.8},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', flex: 0.8},
        },
        actions:{
            /*downloadPDF:{
                fun: (entity) => downloadPDF(entity),
            },*/
        },
        keyComponent: 'purchaseinvoiceCrudManagement'
    },
    uiForm : {
        fields:{
            purchaseinvoiceid : {name: 'purchaseinvoiceid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true, size: 2},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 2, optional: false},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 2, optional: true},
            reference : {name: 'reference', label: 'Referencia',typeName:'String', optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            purchaseinvoicestatusid : {name: 'purchaseinvoicestatusid', label: 'Estado' ,typeName: 'es.rbm.model.jpa.Purchaseinvoicestatus', addObject: purchaseinvoicestatusUI},
            providerid : {name: 'providerid', label: 'Proveedor',typeName:'es.rbm.model.jpa.Provider', addObject: providerUILocal},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', flex: 0.8},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', flex: 0.8, optional: true, size: 12 , multiline: true, rows: 3},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            date_1: new Date(new Date().setDate(new Date().getDate() - 7))
        },
        fields: {
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            providerid : {name: 'providerid', label: 'Proveedor' ,typeName: 'es.rbm.model.jpa.Provider', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            date_1 : {name: 'date_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            date_2 : {name: 'date_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
            purchaseinvoicestatusid : {name: 'purchaseinvoicestatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Purchaseinvoicestatus', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    importComponent : {component : (setOpenImportData, openImportData) => <PurchaseinvoiceExtractor setOpenImportData={setOpenImportData} openImportData={openImportData}/> },
    uiTab: (purchaseinvoice) => getUiTab(purchaseinvoice)
}
