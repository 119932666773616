import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {doGetDownloadFile, doGetPDF} from "../../../../../../Utils/Restclient/NetworkActions";
import DetailsInventoryDialog from "./DetailsInventoryDialog";
import * as React from "react";

const getUiTab = (inventoryid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Inventario", component: <EditObjectDialog objectid={inventoryid} entityDefinition={inventoryUI}/> },
                {label : "Detalles", component: <DetailsInventoryDialog selected={inventoryid}/> },
            ]}
    })
}

const viewPDF = (inventoryid, setPDF) =>{
    doGetPDF('inventory/downloadInvoice/' + inventoryid.inventoryid,null, result =>{
        setPDF(result);
    })
}

export const inventoryUI = {
    entity : {
        name: 'inventory',
        keyField: 'inventoryid',
        label: 'Inventario',
        createUrl: "stock/createNewInventory"
    },
    uiTable: {
        fields: {
            inventoryid : {name: 'inventoryid', label:'#' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            inventory : {name: 'inventory', label: 'Descripción',typeName:'String', optional: true, align: 'center'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            warehouseid : {name: 'warehouseid', label: 'Almacén',typeName:'es.rbm.model.jpa.Warehouse', urlRecords: 'rest/warehouse/getActives'},
            //complete : {name: 'complete', label:'Completo' , typeName: 'Boolean'},
        },
        actions:{},
        keyComponent: 'inventoryCrudManagement'
    },
    uiForm : {
        fields: {
            inventoryid : {name: 'inventoryid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            inventory : {name: 'inventory', label: 'Descripción',typeName:'String', optional: true, align: 'center'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            warehouseid : {name: 'warehouseid', label: 'Almacén',typeName:'es.rbm.model.jpa.Warehouse', urlRecords: 'rest/warehouse/getActives'},
            dumpStock : {name: 'dumpStock', label: 'Volcar stock',typeName:'Boolean', optional: true},
            //complete : {name: 'complete', label:'Completo' , typeName: 'Boolean', optional: true},
        },
        excludeOnAdd:[],
        excludeOnEdit:['dumpStock', 'complete'],
    },
    uiTab: (inventory) => getUiTab(inventory)
}

