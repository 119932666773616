import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import PaymentmethodtimeDialog from "../Other/PaymentmethodtimeDialog";
import * as React from "react";
import BankaccountDialog from "./bankaccountDialog";

const getUiTab = (bankid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Banco", component: <EditObjectDialog objectid={bankid} entityDefinition={bankUI}/> },
                {label : "Cuentas", component: <BankaccountDialog bankid={bankid}/> },
            ]}
    })
}


export const bankUI = {
    entity : {
        name: 'bank',
        keyField: 'bankid',
        label: 'Banco',
        info: {typeName: 'es.rbm.model.jpa.Bank'},
    },
    uiTable: {
        fields:{
            bankid : {name: 'bankid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            bank : {name: 'bank', label:'Banco' , typeName: 'String', editable: true, flex: 1.5},
            entitycode : {name: 'entitycode', label:'Código entidad' , typeName: 'String', editable: true, align: 'center', optional: true},
            bic : {name: 'bic', label:'BIC' , typeName: 'String', editable: true, align: 'center', optional: true},
        },
        keyComponent: 'bankCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            bankid : {name: 'bankid', label:'#' , typeName: 'Number', editable: false, optional: true},
            bank : {name: 'bank', label:'Banco' , typeName: 'String', editable: true},
            entitycode : {name: 'entitycode', label:'Código entidad' , typeName: 'String', editable: true, align: 'center', optional: true},
            bic : {name: 'bic', label:'BIC' , typeName: 'String', editable: true, align: 'center', optional: true},}
    },
    filters : {
        startBegin : false,
        fields: {},
    },
    uiTab: (bankid) => getUiTab(bankid)
}

