import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";

const getUiTab = (accountingaccountid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Cuenta contable", component: <EditObjectDialog objectid={accountingaccountid} entityDefinition={accountingAccountUI}/> },
            ]}
    })
}


export const accountingAccountUI = {
    entity : {
        name: 'accountingaccount',
        keyField: 'accountingaccountid',
        label: 'Cuenta contable',
        info: {typeName: 'es.rbm.model.jpa.Accountingaccount'},
        urlExcelImport: 'importer/importAccountingaccountFromExcel',
        urlDownloadTemplate: 'importer/getAccountingaccountTemplate',
    },
    uiTable: {
        fields:{
            accountingaccountid : {name: 'accountingaccountid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            account : {name: 'account', label:'Nº Cuenta' , typeName: 'Number', editable: true, flex: 1.5},
            description : {name: 'description', label:'Descripción' , typeName: 'String', editable: true, align: 'center'},
            externalcode : {name: 'externalcode', label:'Código externo' , typeName: 'String', editable: true, optional: true},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center'},
        },
        keyComponent: 'accountingaccountCrudManagement',
        actions:{},
    },
    uiForm : {
        fields:{
            accountingaccountid : {name: 'accountingaccountid', label:'#' , typeName: 'Number', editable: false, optional: true},
            account : {name: 'account', label:'Nº Cuenta' , typeName: 'Number', editable: true},
            description : {name: 'description', label:'Descripción' , typeName: 'String', editable: true, align: 'center'},
            externalcode : {name: 'externalcode', label:'Código externo' , typeName: 'String', editable: true, optional: true},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center'},
        }
    },
    filters : {
        startBegin : false,
        fields: {},
    },
    uiTab: (accountingaccountid) => getUiTab(accountingaccountid)
}

