import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../SingleRecordForm";
import Grid from "@mui/material/Grid";
import {movementunitUI} from "../../../Solutions/ERP/src/Maintenance/Crud/Movementunit/movementunitCrud";
import SaveRoundedButton from "../../Button/SaveRoundedButton";
import SuccessButton from "../../Button/SuccessButton";
import {accountingAccountUI} from "../../../Solutions/ERP/src/Maintenance/Crud/Accounting/accountingaccountCrud";
import uiDefinition from "../../../Utils/uiDefinition";

export default function NewEntityCollectionDialog(props) {
    const {onClose, parentid, info} = props;
    const [state, setState] = React.useState({complete: false, record: {}});
    const {complete, record} = state;
    const parentInfo = uiDefinition[info.parentTypeName];
    const childrenInfo = uiDefinition[info.childrentypeName];

    const handledChange = (record, complete) =>{
        setState({...state, record:{...record}, complete: complete});
    }

    const addEntity = (entity, callback) => {
        const toSend = {...entity, [parentInfo.keyField]: parentid}
        doPost('rest/' + childrenInfo.entityName + '/create', toSend, () => callback && callback())
    }

    useEffect(() => {}, [])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addEntity(record, () => onClose && onClose());
    };

    return(
        <Box sx={{m: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={info.uiForm} records={record} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!complete}/>
        </Box>
    );
}
