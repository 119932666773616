import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {updateSalenotedetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";

const filterOrders = (records, callback) => {
    if (records.salenoteid) {
        const toSend = {...records.salenoteid?.saleorderid};
        doPost('saleorder/getSaleorderproducts', toSend, r => {
            let products = r.map(r => r.productid);
            callback && callback(products);
        })
    } else {
        callback && callback([])
    }
}

const ui = {
    fields:{
        salenotedetailid : {name: 'salenotedetailid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: true, filterRecords: filterOrders},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: true, urlRecords: 'rest/warehouse/getActives'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true},
    }
}

export default function EditSalenotedetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [salenotedetailid, setSalenotedetail] = React.useState(selected || {});
    const [productQuantity, setProductQuantity] = React.useState([]);
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo detalle');

    const handledChange = (record, complete) => {
        let filter = Object.keys(record).filter(key => key === 'quantity');
        if ((record.productid && !filter.length) || salenotedetailid?.productid?.productid !== record?.productid?.productid) {
            let quantityRecord = productQuantity.filter(p => p.productid?.productid === record?.productid?.productid);
            if (quantityRecord.length) {
                record.quantity = quantityRecord[0].quantity;
            }
        }
        setSalenotedetail({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if(salenotedetailid && salenotedetailid.salenotedetailid){
            setTitle('Editar detalle')
        }
        if (salenotedetailid.salenoteid) {
            const toSend = {...salenotedetailid.salenoteid?.saleorderid};
            doPost('saleorder/getSaleorderproducts', toSend, r => {
                setProductQuantity(r);
            })
        }
    }, [props])

    const onSuccessClick = (e) => {
        e.preventDefault()
        updateSalenotedetail(salenotedetailid, () => setOpen(!open))
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen(!open)
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Grid container>
                <Grid item md={1}/>
                <Grid item md={10}>
                    <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
                    <SingleRecordForm ui={ui} records={salenotedetailid} onChange={handledChange} disableAutoFocus />
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button size={'small'} variant="contained" color="error" onClick={onNegativeClick}>X</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
