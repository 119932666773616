import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {
    doGet,
    doGetDownloadFile,
    doGetPDF,
    doPostDownloadFile, doPostPDF
} from "../../../../../../Utils/Restclient/NetworkActions";
import * as React from "react";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import DetailsSaleorderDialog from "../../../Maintenance/Crud/Saleorder/DetailsSaleorderDialog";
import {customerUI} from "../../../Maintenance/Crud/Customer/customerCrud";
import {saleorderstatusUI} from "../../../Maintenance/Crud/Saleorder/saleorderstatusUI";
import {paymentmethodUI} from "../../../Maintenance/Crud/Other/paymentmethodCrud";
import AlusaleorderHouseView from "./AlusaleorderHouseView";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {constructionUI} from "../Construction/constructionCrud";
import {townUI} from "../../../Maintenance/Crud/Other/townCrud";
import {stateUI} from "../../../Maintenance/Crud/Other/stateCrud";
import {countryUI} from "../../../Maintenance/Crud/Other/countryCrud";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";

const getUiTab = (saleorderid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Presupuesto", component: <EditObjectDialog objectid={saleorderid} entityDefinition={workbudgetUI}/> },
                {label : "Detalles", component: <DetailsSaleorderDialog selected={saleorderid} entityDefinition={workbudgetUI}/> },
                {label : "Viviendas", component: <AlusaleorderHouseView alusaleorderid={saleorderid}/> },
                //{label : "Facturas", component: <SaleorderSaleinvoiceView saleorderid={saleorderid}/> },
                //{label : "Albaránes", component: <SaleorderSalenoteView saleorderid={saleorderid}/> },
            ]}
    })
}

const viewPDF = (saleorderid, setPDF) =>{
    doGetPDF('saleorder/downloadSaleorder/' + saleorderid.saleorderid,null, result =>{
        setPDF(result);
    })
}

const sendMail = (saleorderid, setOpenMail, setMailRecords) =>{
    const to = saleorderid.email || saleorderid.customerid?.email;
    setMailRecords({to: to, subject: 'Presupuesto '+ saleorderid.saleorderid + ': ' + saleorderid.saleorder})
    setOpenMail(true);
}

const getReportOptions = (setOption) => {
    doGet('saleorder/getSaleorderReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    const url = 'saleorder/downloadSaleorderReport/' + selected.saleorderid;
    let fileName = op.label + '_' + selected.saleorderid + op.extension;
    showOrDownloadDocument(url, op, fileName, pdfSet);
}

const autoComplete = (record, field, value, callback) => {
    const valuesToChange = ['townid', 'postalcode', 'stateid', 'countryid']
    if (field === 'customerid') {
        const newRecord = {...record};
        const customerid = record?.customerid;
        if (customerid) {
            valuesToChange.forEach(value => {
                newRecord[value] = customerid ? customerid[value] : null;
            })
            newRecord.firstaddress = customerid.address
        } else {
            valuesToChange.forEach(value => {
                newRecord[value] = null;
            })
            newRecord.firstaddress = null;
        }
        callback && callback({...newRecord});
    } else if (field === 'contractorid') {
        const newRecord = {...record};
        const contractorid = record?.contractorid;
        newRecord.serie = contractorid?.seriesaleorder;
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}

export const getConstructions = (record, callback) =>{

    if (record.customerid) {
        let filters = {
            entity : {name: 'construction'},
            fields: {
                customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: record.customerid},
                active : {name: 'active', operator: OPERATORS.OPERATOR_EQUAL, value: true},
            }
        }
        searchByCriteria(filters, result => {
            if (result && result.length > 0) {
                let toReturn = [...result];
                callback && callback(toReturn)
            } else {
                callback && callback([])
            }
        });
    }
    callback && callback([]);
}

export const workbudgetUI = {
    entity : {
        name: 'alusaleorder',
        keyField: 'saleorderid',
        label: 'Presupuesto',
        colorValue: 'saleorderstatusid',
        info: {typeName: 'es.rbm.model.jpa.Alusaleorder'}
    },
    uiTable: {
        fields:{
            saleorderid : {name: 'saleorderid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            saleorder : {name: 'saleorder', label:'Descripción' , typeName: 'String', editable: true, optional: true, align: 'center'},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer'},
            constructionid : {name: 'constructionid', label: 'Obra',typeName:'es.rbm.model.jpa.Construction'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            deliverydate : {name: 'deliverydate', label: 'Fecha de entrega' ,typeName: 'Date'},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Saleorderstatus', align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', align: 'center'},
        },
        actionFlex: 1.1,
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            },
            sendEmail:{
                fun: (entity, setOpenMail, setMailRecords) => sendMail(entity, setOpenMail, setMailRecords)
            },
        },
        keyComponent: 'workbudgetCrudManagement'
    },
    uiForm : {
        fields:{
            saleorderid : {name: 'saleorderid', label: 'Código' ,typeName: 'String', editable: false, optional: true, size: 2},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 2},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 2, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            saleorder : {name: 'saleorder', label: 'Descripción' ,typeName: 'String', size: 12},
            date : {name: 'date', label: 'Fecha' ,typeName: 'Date', size: 3},
            deliverydate : {name: 'deliverydate', label: 'Fecha de entrega' ,typeName: 'Date', size: 3, empty: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', addObject: customerUI, size: 6},
            constructionid : {name: 'constructionid', label: 'Obra',typeName:'es.rbm.model.jpa.Construction', filterRecords: getConstructions, addObject: constructionUI},
            countryid : {name: 'countryid', label: 'País' ,typeName: 'es.rbm.model.jpa.Country', addObject: countryUI},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado' ,typeName: 'es.rbm.model.jpa.Saleorderstatus', addObject: saleorderstatusUI},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center', addObject: paymentmethodUI},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', multiline: true, rows: 3, size: 12, optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        fields: {
            saleorderid : {name: 'saleorderid', label: 'Nº pedido' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date', label: 'Fecha' ,typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'Number', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            deliverydate : {name: 'deliverydate', label: 'Fecha de entrega' ,typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            constructionid : {name: 'constructionid', label: 'Obra' ,typeName: 'es.rbm.model.jpa.Construction', operator: OPERATORS.OPERATOR_EQUAL, filterRecords: getConstructions, optional: true},
            saleorderstatusid : {name: 'saleorderstatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Saleorderstatus', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    uiTab: (saleorder) => getUiTab(saleorder)
}
