import React, {useEffect, useState} from "react";
import { FileUploader } from "react-drag-drop-files";
import {fileToArrayBytes, fileToBase64} from "./utils";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const defaultFileTypes = ["PDF", "XLS", "XLSX"];

function DragDropFiles(props) {
    const {onLoadFile, name, convertToBase64, types} = props;
    const [file, setFile] = useState(null);
    const filetypes = types || defaultFileTypes;
    const handleChange = (file) => {
        setFile(file);
        if (convertToBase64) {
            fileToBase64(file, (result) =>  onLoadFile({target: {name: file.name, value: result}}))
        } else{
            fileToArrayBytes(file, (result) =>  onLoadFile({target: {name: file.name, value: result}}))
        }
    };

    useEffect(() =>{
        setFile(props.file)
    }, [props])

    const children = () => {
        return(
            <Box>
                <Button fullWidth size={'large'} variant="outlined">
                    <Grid container sx={{mt:1}} alignContent={'center'} alignItems={'center'}>
                        <Grid item md={11}>
                            <InsertDriveFileIcon />
                        </Grid>
                        <Grid item md={1}>
                            {file? <CheckRoundedIcon color='success'/> :''}
                        </Grid>
                        <Grid item md={12}>
                            Inserte un documento
                        </Grid>
                    </Grid>
                </Button>
            </Box>
        )
    }

    return (
        <Grid container justifyItems={'center'} justifyContent={'center'}>
            <Grid item md={12} >
                <FileUploader name={name}
                              children={children()}
                              handleChange={handleChange} types={filetypes} />
            </Grid>
        </Grid>
    );
}

export default DragDropFiles;

DragDropFiles.propTypes = {
    onLoadFile: PropTypes.func,
    name: PropTypes.string,
    convertToBase64: PropTypes.bool,
    types: PropTypes.array
}
