import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {
    doGet,
    doGetDownloadFile,
    doGetPDF,
    doPost,
} from "../../../../../../Utils/Restclient/NetworkActions";
import * as React from "react";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import DetailsAlusalenoteDialog from "./DetailsAlusalenoteDialog";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";

const getUiTab = (salenoteid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Albarán de entrega", component: <EditObjectDialog objectid={salenoteid} entityDefinition={alusalenoteUI}/> },
                {label : "Detalles", component: <DetailsAlusalenoteDialog selected={salenoteid}/> },
            ]}
    })
}

const viewPDF = (salenoteid, setPDF) =>{
    doGetPDF('salenote/downloadSalenote/' + salenoteid.salenoteid, result =>{
        setPDF(result);
    })
}

const filterOrders = (records, callback) => {
    if (records.customerid) {
        const toSend = {customerid: records.customerid};
        doPost('salenote/getPendingOrders', toSend, r => {
            callback && callback(r);
        })
    } else {
        callback && callback([])
    }
}

const getReportOptions = (setOption) => {
    doGet('salenote/getSalenoteReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    const url = 'salenote/downloadSalenoteReport/' + selected.salenoteid;
    let fileName = op.label + '_' + selected.purchaseorderid + op.extension;
    showOrDownloadDocument(url, op, fileName, pdfSet);
}

const autoComplete = (record, field, value, callback) =>{
    if (field === 'customerid') {
        const newRecord = {...record};
        newRecord.saleorderid = null
        callback && callback({...newRecord});
    } else if (field === 'saleorderid') {
        const newRecord = {...record};
        newRecord.serie = record?.saleorderid?.contractorid?.seriesalenote;
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}

export const alusalenoteUI = {
    entity : {
        name: 'salenote',
        keyField: 'salenoteid',
        label: 'Albarán de entrega',
        colorRow: 'saleinvoiceid',
    },
    uiTable: {
        fields: {
            salenoteid : {name: 'salenoteid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            salenote : {name: 'salenote', label: 'Albarán',typeName:'String'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer'},
            signature : {name: 'signature', label:'Firmado' , typeName: 'Boolean', flex: 0.5},
            saleinvoiceid : {name: 'saleinvoiceid', label:'Factura' , typeName: 'es.rbm.model.jpa.Saleinvoice', flex: 0.5, delimiter: '' },
            saleorderid : {name: 'saleorderid', label:'Presupuesto' , typeName: 'es.rbm.model.jpa.Saleorder', flex: 0.8, align: 'center'},
            remarks : {name: 'remarks', label: 'Observaciones' , typeName: 'String', align: 'center', optional: true},
        },
        actionFlex: 0.5,
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            }
        },
        keyComponent: 'alusalenoteCrudManagement'
    },
    uiForm : {
        fields:{
            salenoteid : {name: 'salenoteid', label:'Cod.' , typeName: 'Number', editable: false, size: 1, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 1},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 1, optional: true},
            salenote : {name: 'salenote', label: 'Descripción',typeName:'String', optional: true, size: 9},
            date : {name: 'date', label: 'Fecha',typeName:'Date', size: 3},
            customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', size: 4.5},
            saleorderid : {name: 'saleorderid', label:'Presupuesto' , typeName: 'es.rbm.model.jpa.Alusaleorder', size: 4.5, align: 'center', filterRecords: filterOrders},
            remarks : {name: 'remarks', label: 'Observaciones' , typeName: 'String', size: 12, multiline: true, rows: 5, optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) => {
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        fields: {
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'Number', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    uiTab: (salenote) => getUiTab(salenote)
}

