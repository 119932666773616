import React, { useEffect, useState } from "react";
import {doExternalPost, doGet} from "../Utils/Restclient/NetworkActions";
import Box from "@mui/material/Box";
import {Card, CardActionArea, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {error} from "../Utils/Notification/notifications";

/*const dashBoards = [
    {
        dashboardid : 1,
        dashboard : 'Ventas',
        description : 'Dashboard donde se pueden ver todas las ventas en un intervalo de fechas...',
        key : 'wjPkavegZyp',
    },
    {
        dashboardid : 2,
        dashboard : 'Compras',
        description : 'Dashboard donde se pueden ver todas las compras en un intervalo de fechas...',
        key : 'nVYpleKgqwW',
    },
    {
        dashboardid : 3,
        dashboard : 'Nombres personas EEUU',
        description : 'Estadística de todos los nombre de EEUU de personas tanto chicos como chicas...',
        key : '13GlM64kvM8',
    }
]*/


const SupersetDashboard = (props) => {
    const [state, setState] = useState({});
    const supersetApiUrl = "https://superset.sibema.es/api/v1/security/login";
    const {dashBoards} = state;

    useEffect(() => {
        doGet("rest/dashboard/getAll", records =>{
            setState({...state, dashBoards: [...records]})
        })
    }, [])

    const openDashboard = (option) => {
        const {username, password, key} = option;
        if (username && password && key) {
            const credentials = {
                username,
                password,
                provider: "db",
                refresh: true,
            };
            doExternalPost(supersetApiUrl, credentials, response => {
                const {access_token} = response;
                if (access_token) {
                    const dashboardUrl = `http://superset.sibema.es/superset/dashboard/p/${key}?standalone=true&token=${access_token}`;
                    window.open(dashboardUrl, '_blank');
                }
            })
        } else {
            error("La configuración del dashboard es incorrecta");
        }
    };

    const onSelectOption = (option) => {
        openDashboard(option);
    }

    return (
        <Box sx={{m: 5}}>
            {dashBoards && <SelectionActionCard cards={dashBoards} onSelectCard={onSelectOption}/>}
        </Box>
    );
};

function SelectionActionCard(props) {
    const {cards, onSelectCard} = props;
    let onClick = (card, index) => {
        onSelectCard && onSelectCard(card);
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(min(200px, 100%), 1fr))',
                gap: 2,
            }}
        >
            {cards.map((card, index) => {
                return (
                    <Card>
                        <CardActionArea
                            onClick={() => onClick(card, index)}
                            sx={{
                                height: '100%',
                                '&[data-active]': {
                                    backgroundColor: 'action.selected',
                                    '&:hover': {
                                        backgroundColor: 'action.selectedHover',
                                    },
                                },
                            }}
                        >
                            <CardContent sx={{height: '100%'}}>
                                <Typography variant="h5" component="div">
                                    {card.dashboard}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {card.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                );
            })}
        </Box>
    );
}
export default SupersetDashboard;
