import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {GridEditModes} from "@mui/x-data-grid";
import NewProductContainerDialog from "../Product/NewProductContainerDialog";
import CustomDialog from "../../../../../../View/CustomDialog";
import NewBankaccountDialog from "./NewBankAccountDialog";


const ui = {
    fields: {
        bankaccountid : {name: 'bankaccountid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: true, visible: false, editable: false},
        bankaccount : {name: 'bankaccount', label:'Cuenta banco' , typeName: 'String', optional: false},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', optional: false},
        accountingaccountid : {name: 'accountingaccountid', label:'Cuenta contable' , typeName: 'es.rbm.model.jpa.Accountingaccount', optional: false},
        iban : {name: 'iban', label:'IBAN' , typeName: 'String', optional: false},
    },
    sort: {
        field: 'bankaccount',
        sort: 'asc'
    },
    keyComponent: 'BankaccountDialog',
}

export default function BankaccountDialog(props) {
    const {bankid} = props;
    const [state, setState] = React.useState({records: []});
    const [openNewBankAccount, setOpenNewBankAccount] = React.useState(false);

    const {records} = state;

    useEffect(() => {
        if (!openNewBankAccount) {
            refresh();
        }
    }, [bankid, openNewBankAccount])

    const refresh = () => {
        setState({...state, records: []})
        if (bankid) {
            const filters = {
                entity : {name: 'bankaccount'},
                fields: {
                    bankid : {
                        name: 'bankid',
                        operator: OPERATORS.OPERATOR_EQUAL,
                        value: bankid
                    }
                },
            }
            searchByCriteria(filters, result =>{
                if (result) {
                    setState({...state, records: result})
                }
            });
        }
    }
    
    const onDelete = (bankaccount) => {
        doDelete('rest/bankaccount/delete/' + bankaccount.bankaccountid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const {records} = state;
        const record = records.filter(r => r.bankaccountid === e.bankaccountid);
        if (record.length) {
            doPutRest('bankaccount', e, () => refresh());
        } else {
            delete e.bankaccountid
            doPutRest('bankaccount', e, () => refresh());
        }
    }

    const addRecord = () => {
        setOpenNewBankAccount(true);
    }

    return(
        <Box sx={{m: 2, alignItems: 'left', width: '100%' }}>
            <CustomDialog fullWidth maxWidth={"md"} title={'Nueva cuenta'}
                          open={openNewBankAccount} onClose={() => setOpenNewBankAccount(false)}>
                <NewBankaccountDialog bankid={bankid} setOpen={setOpenNewBankAccount} open={openNewBankAccount}/>
            </CustomDialog>
            <Button size={'small'} variant="contained" onClick={addRecord} startIcon={<AddIcon />} sx={{m: 1}}>
                Añadir cuenta
            </Button>
            <GridTable ui={ui} onDelete={onDelete}
                       editMode={GridEditModes.Row}
                       records={records} rowId={'bankaccountid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '20vh', bgcolor: '#ffffff'}}
            />
        </Box>
    )
}
