import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../View/Button/SuccessButton";
import {doPostRest, doPostRestList} from "../../../../../Utils/Restclient/NetworkRestActions";
import {timeticktypes} from "../../../../../Utils/Constant";
import {getKeyByValue} from "../../../../../Utils/UtilsCommon";
import CustomDialog from "../../../../../View/CustomDialog";



const defaultUI = {
    fields:{
        timeticktype : {name: 'timeticktype', label:'Tipo' , typeName: 'Enum', valueOptions: timeticktypes, size: 6},
        timetick : {name: 'timetick', label:'Fecha' , typeName: 'Datetime', size: 6},
        taskid : {name: 'taskid', label:'Tarea' , typeName: 'es.rbm.model.jpa.Task', size: 6, optional: true},
        workspaceid : {name: 'workspaceid', label:'Espacio' , typeName: 'es.rbm.model.jpa.Workspace', size: 6, optional: true},
        workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', size: 12, isMulti: true},

    }
}

const types = {"ENTRADA" : 1, "SALIDA" : 2}

export default function EditTimetickDialog(props) {
    const {selected, onClose, open} = props;
    const [timetickid, setTimetickid] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo marcaje');
    const [ui, setUI] = React.useState(defaultUI);
    const [editMode, setEditMode] = React.useState(false);

    const handledChange = (record, complete) => {
        setTimetickid({...record});
        setComplete(complete);
    }

    useEffect(() => {
        const {selected} = props;
        setTimetickid(selected || {});
        if (selected && selected.timetickid && selected.timeticktype) {
            setEditMode(true);
            const key = getKeyByValue(types, selected?.timeticktype);
            key && setTimetickid({...selected, timeticktype: key});
            setTitle('Editar marcaje')
            const newUI = {
                fields: {...defaultUI.fields}
            }
            newUI.fields.workerid.isMulti = false;
            setUI(newUI);
        } else {
            const newUI = {
                fields: {...defaultUI.fields}
            }
            newUI.fields.workerid.isMulti = true;
            setUI(newUI);
            setEditMode(false);
            setTitle('Nuevo marcaje')
        }
    }, [props])

    const onSuccessClick = () => {
        if (editMode) {
            const toSend = {...timetickid, timeticktype: types[timetickid.timeticktype]}
            doPostRest('timetick', toSend, onClose)
        } else {
            const {timetick, workerid, timeticktype, taskid, workspaceid} = timetickid;
            const toSend = workerid.map(w => {
                return {timetick, taskid, workspaceid, workerid: w, timeticktype: types[timeticktype]}
            });
            doPostRestList('timetick', toSend, onClose)
        }
    };

    return(
        <CustomDialog title={title} fullWidth open={open} onClose={onClose}>
            <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                <SingleRecordForm ui={ui} records={timetickid} onChange={handledChange} disableAutoFocus />
                <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
            </Box>
        </CustomDialog>
    )
}
