import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ExpandLess, ExpandMore, StarBorder} from "@mui/icons-material";
import {Collapse, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import StopIcon from '@mui/icons-material/Stop';

export default function TestSidebar(props) {
    const {sidebar, padding, main, setComponent, closeParent, expansible, clearFilter} = props;

    useEffect(() => {}, [props])

    return (
        <List overflow={main ? 'auto' : null} sx={{ height: '100%', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} disablePadding component="nav">
            {sidebar && sidebar.map((entry, index) => <RowEntry clearFilter={clearFilter} expansible={expansible} closeParent={closeParent} main={main} id={entry.key + index} key={entry.key + index} setComponent={setComponent} entry={entry} padding={(padding || 0)+1}/>)}
        </List>
    );
}

const RowEntry = (props) => {
    const {entry, padding, setComponent, closeParent, expansible, clearFilter} = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(entry.children && expansible);
    }, [props])

    const handleClick = (entry) => {
        if (entry?.children?.length > 0) {
            setOpen(!open);
        } else {
            closeParent && closeParent()
            entry.component && setComponent(entry);
        }
    };

    const closeThis = () => {
        setOpen(false);
        clearFilter && clearFilter();
    }

    return (
        <Box>
            <ListItemButton disabled={entry.disabled} selected={open} onClick={() => handleClick(entry)} >
                <ListItemIcon sx={{transition: '0.2s', ml: 1}}>
                    {entry.children ? entry?.icon : <StopIcon fontSize={'small'} color={'warning'}/>}
                </ListItemIcon>
                <ListItemText sx={{ml: -3, my: entry.children ? 0 : -0.1}} primary={entry?.label} primaryTypographyProps={{color: 'black', fontWeight:  entry?.children && 'bold', variant: 'strong'}} />
                {entry?.children?.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : ''}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{pl: padding}}>
                    <TestSidebar expansible={expansible} clearFilter={clearFilter} closeParent={closeThis} setComponent={setComponent} sidebar={entry?.children}/>
                </List>
            </Collapse>
            {entry.divider && <Divider/>}
        </Box>
    )
}
