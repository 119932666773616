import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {Collapse, Dialog, IconButton, Stack, TableCell} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {doDelete, doGet, doPost, doPostDownloadFile} from "../../../../../../Utils/Restclient/NetworkActions";
import PrintIcon from '@mui/icons-material/Print';
import {getSaleinvoicedetails} from "./actions";
import AddSalenoteDialog from "./AddSalenoteDialog";
import MenuButton from "../../../../../../View/Button/MenuButton";
import Grid from "@mui/material/Grid";
import GridTable from "../../../../../../View/Form/GridTable";
import Paper from "@mui/material/Paper";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {TotalizerRow} from "../../../../../../View/TotalizerRow";
import {GridEditModes} from "@mui/x-data-grid";
import CustomDialog from "../../../../../../View/CustomDialog";


const ui = {
    fields: {
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 3},
        saleinvoicedetail : {name: 'saleinvoicedetail', label: 'Concepto',typeName:'String', flex: 3, align: 'center'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        price : {name: 'price', label:'Precio' , typeName: 'Number'},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number'},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch'},
        vatid : {name: 'vatid', label:'IVA' , typeName: 'es.rbm.model.jpa.Vat'},
        salenotedetailid : {name: 'salenotedetailid', label:'Albarán', typeName: 'Boolean'},
    },
    sort: {
        field: 'saleinvoicedetailis',
        sort: 'desc'
    },
    keyComponent: 'saleinvoiceDetails',
}

const uiTotalizer = {
    columns: {
        baseAmount: 'Base (€)',
        vatType: 'Tipo IVA',
        vatAmount: 'Total IVA (€)',
        total: 'TOTAL (€)'
    }
}

export default function DetailsSaleInvoiceDialog(props) {
    const {saleinvoiceid} = props;
    const [records, setRecords] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [reportOptions, setReportOptions] = React.useState(null);
    const [details, setDetails] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [open])

    const refresh = () => {
      doPost('saleinvoice/getSaleinvoiceInfo', {...saleinvoiceid}, result => {
          setRecords(result)
          getSaleinvoicedetails(saleinvoiceid, saleinvoicedetails => {
              setDetails(saleinvoicedetails);
              getReportOptions();
          })
      });
    }

    const onSelectReport = (op) => {
        doPostDownloadFile('saleinvoice/downloadSaleinvoiceReport/' + saleinvoiceid.saleinvoiceid, op, op.label + '_' + saleinvoiceid.saleinvoiceid + '.pdf');
    }

    const getReportOptions = () => {
        doGet('saleinvoice/getSaleinvoiceReportOptions/' + saleinvoiceid.saleinvoiceid, result => {
            setReportOptions(result);
        });
    }

    const onDelete = (saleinvoicedetailid) => {
        doDelete('rest/saleinvoicedetail/delete/' + saleinvoicedetailid.saleinvoicedetailid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const record = details.filter(r => r.saleinvoicedetailid === e.id);
        if (record.length) {
            record[0][e.field] = e.value;
            doPutRest('saleinvoicedetail', record[0], () => refresh());
        } else {
            doPutRest('saleinvoicedetail', e, () => refresh());
        }
    }

    const addNewDetailFromSalenotedetail = (e) => {
        const toSend = {saleinvoiceid: saleinvoiceid, salenotedetailid: e.salenotedetailid}
        doPost("saleinvoice/addSaleinvoicedetailFromSalenotedetail", toSend, () => refresh());
    }

    const addNewDetail = (e) => {
       doPutRest('saleinvoicedetail', e, () => refresh())
    }

    return(
        <Box sx={{m: 2, alignItems: 'center', width: '100%' }} align={'center'}>
            <CustomDialog fullWidth title={'Añadir albarán'}
                          maxWidth="md" open={open} onClose={()=> setOpen(false)}>
                <AddSalenoteDialog saleinvoiceid={saleinvoiceid} setOpen={setOpen} open={open}/>
            </CustomDialog>
            <Stack direction="row"
                   spacing={2}
                   sx={{mb: 2}}
                   justifyContent="center"
                   alignItems="center">
                <Button size={'small'} onClick={() => setOpen(true)} variant="contained" startIcon={<AddIcon />} sx={{m: 1, mb:2}}>
                    Añadir albarán
                </Button>
                <Button size={'small'} variant="contained" onClick={() => addNewDetail({saleinvoiceid: saleinvoiceid})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!saleinvoiceid?.saleinvoiceid}>
                    Añadir detalle
                </Button>
                <MenuButton
                    label={'IMPRIMIR'}
                    startIcon={<PrintIcon/>}
                    onClickOption={onSelectReport}
                    options={reportOptions || []}
                />
            </Stack>
            <Grid container spacing={2}>
                {records && records.salenotes?.length ? <Grid item  sx={{height: '65vh'}} md={4} overflow={'auto'}>
                    { records.salenotes.sort((salenoteA, salenoteB) => salenoteA.salenote.salenoteid < salenoteB.salenote.salenoteid ? 1 : 0).map((salenote, index) =>
                        <Row refresh={refresh} salenote={salenote} key={index} onDelete={onDelete} addNewDetail={addNewDetailFromSalenotedetail}/>)}
                </Grid> : '' }
                <Grid item md={records && records.salenotes?.length ? 8 : 12}>
                    {details?.length ?
                        <Box>
                            <Paper sx={{p:1}}>
                                <GridTable ui={ui} onDelete={onDelete}
                                           editMode={GridEditModes.Cell}
                                           records={details} rowId={'saleinvoicedetailid'}
                                           onChange={onChange} density={'compact'} hideFooter={true}
                                           toolBar sx={{height: '65vh'}}
                                />
                            </Paper>
                        </Box> : ''
                    }
                </Grid>
                <Grid item md={12}>
                    { records && <TotalizerRow ui={uiTotalizer} data={records.totalizer}/> }
                </Grid>
            </Grid>
        </Box>
    )
}

const Row = (props) => {
    const {salenote, onDelete, addNewDetail} = props;
    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };
    const [open, setOpen] = React.useState(false);
    return(
        <Table stickyHeader sx={{backgroundColor: 'white', mb: 1}}  >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{width: '10%'}}>
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align={'center'} style={{width: '35%'}}>
                        <strong>{'Albarán: '}</strong> {salenote.salenote.serie + salenote.salenote.number}
                    </TableCell>
                    <TableCell align={'center'} style={{width: '35%'}}>
                        <strong>{'Fecha: '}</strong> {new Date(salenote.salenote.date).toLocaleDateString('es-ES', options)}
                    </TableCell>
                    <TableCell align={'center'} style={{width: '25%'}}>
                        <strong>{'Nº Líneas: '}</strong> {salenote.salenotedetailList.length}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table stickyHeader size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: '5%'}} align={'center'}  component="th" scope="row"> <strong>Nº</strong></TableCell>
                                        <TableCell style={{width: '60%'}} align={'center'}> <strong>Concepto</strong></TableCell>
                                        <TableCell style={{width: '10%'}} align={'center'}> <strong>Unidades</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {salenote.salenotedetailList.map((detailRow, i) => (
                                        <TableRow key={i} style={{ textDecoration: detailRow.saleinvoicedetailid ? 'none' : 'line-through'}}>
                                            <TableCell style={{width: '5%'}} align={'center'} component="th" scope="row"> {i+1}</TableCell>
                                            <TableCell style={{width: '60%'}} align={'center'}> {detailRow.concept} </TableCell>
                                            <TableCell style={{width: '10%'}} align={'center'}> {detailRow.quantity} </TableCell>
                                            <TableCell style={{width: '10%'}} align={'center'}> {detailRow.saleinvoicedetailid ?
                                                <IconButton onClick={() => onDelete(detailRow.saleinvoicedetailid)} value={2} color={'error'} variant="contained" size="small">
                                                    <DeleteIcon/>
                                                </IconButton> :
                                                <IconButton onClick={() => addNewDetail(detailRow)} value={2} color={'success'} variant="contained" size="small">
                                                    <AddIcon/>
                                                </IconButton>
                                            } </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {salenote?.salenote?.remarks?.length ? <RemarksRow remarks={salenote.salenote.remarks}/> : ''}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Table>)
}

const RemarksRow = (props) => {
    const {remarks} = props;
    const [open, setOpen] = React.useState(false);
    return(
        <Table stickyHeader size="small" sx={{backgroundColor: 'white'}}  >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} style={{width: '10%'}}>
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell align={'center'} style={{width: '90%'}}>
                        <strong>{'Observaciones'}</strong>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {remarks}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Table>)
}
