import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import uiDefinition from "../../Utils/uiDefinition";
import {doGet} from "../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../Utils/Persistence/PersistenceQuery";
import {useEffect, useRef} from "react";
import {isObject} from "../../Utils/UtilsCommon";

const converterRecord = (record, props) => {
    const {keyField, descriptiveField, typeName, name} = props;
    if (isObject(record)) {
        if (record) {
            if (keyField && descriptiveField) {
                const result ={};
                result.value = record[keyField];
                result.label = record[descriptiveField];
                return result;
            } else if (uiDefinition[typeName] && uiDefinition[typeName].descriptiveField && uiDefinition[typeName].keyField) {
                const result ={};
                const uiDefinitionElement = uiDefinition[typeName];
                result.value = record[uiDefinitionElement['keyField']];
                result.label ='(' + result.value + ') ' + uiDefinitionElement['descriptiveField'].split('.').reduce((l, c) => { return l + record[c] + " "}, '');
                return result;
            } else if (uiDefinition[typeName]) {
                const result ={};
                result.value = record;
                result.label = record;
                return result;
            } else if (record && name && name.length > 0 && record[name]) {
                const result ={};
                result.value = record[name];
                result.label = record[name.replace('id', '')];
                return result;
            } else {
                const result ={};
                result.value = record;
                result.label = record;
                return result;
            }
        } else {
            return record;
        }
    } else {
        return '';
    }

}

export default function SingleSelectTable(props) {
    const {records, defaultValue, typeName, label, onChange, urlRecords, criteriaFilter, name, filterRecords, groupBy, row, optional} = props;
    const [inputValue, setInputValue] = React.useState('');
    const [open, setOpen] = React.useState(true);
    const [options, setOptions] = React.useState(null);
    const loading = open && options == null;
    const inputRef = useRef(null);
    const textRef = useRef(null);

    const sortOptions = (options) => {
        return groupBy ? options.sort((a, b) => {
            const groupA = groupBy(a);
            const groupB = groupBy(b);
            return groupA.localeCompare(groupB)})
            : options
    }

    const refresh = (callback) => {
        if (props) {
            if (records) {
                setOptions(sortOptions(records));
            } else if (urlRecords) {
                if ((!options?.length)) {
                    doGet(urlRecords, result => {
                        callback && callback(result);
                    })
                }
            } else if (criteriaFilter) {
                    searchByCriteria(criteriaFilter, result => {
                        if (result) {
                            callback && callback(result);
                        }
                    });
            } else if (filterRecords) {
                filterRecords(result => {
                    callback && callback(result);
                })
            } else {
                if ((!options?.length)) {
                    if (uiDefinition[typeName]?.url) {
                        const ui = uiDefinition[props.typeName];
                        doGet(ui.url, result => {
                            if (result){
                                callback && callback(result);
                            }
                        })
                    }
                }
            }
        }
    }

    const handleOnChange = (e, v) => {
        const newRow = {...row};
        newRow[name] = v
        if (inputRef.current) {
            inputRef.current.blur();
            textRef.current.blur();
        }
        onChange && onChange(newRow, row, true);
    }

    const handleInputChange = (e, newValue) => {
        if (e && ['change', 'click', 'keydown'].includes(e.type)) {
            let newInputValue = newValue === 'undefined' ? '' : newValue;
            e && setInputValue(newInputValue)
            e && inputValue !== newInputValue && newInputValue.length === 0 && isObject(newInputValue) && handleOnChange(null, null);
        }
    }

    useEffect(() => {
        loading && refresh(result => setOptions(sortOptions(result)));
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions(null);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-dselect-table"
            freeSolo fullWidth
            open={open}
            onOpen={() => {setOpen(true)}}
            onClose={() => {setOpen(false);}}
            groupBy={groupBy}
            getOptionLabel={(option) => option ? converterRecord(option, props).label : null}
            options={options || []}
            inputValue={inputValue}
            loading={loading}
            onChange={handleOnChange}
            onInputChange={handleInputChange}
            size={'small'}
            renderInput={(params) => <TextField error={!optional && !inputValue} autoFocus {...params} label={label} />}
        />
    );
}
