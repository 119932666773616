import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {GridEditModes} from "@mui/x-data-grid";


const ui = {
    fields: {
        paymentmethodtimeid : {name: 'paymentmethodtimeid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: true, visible: false, editable: false},
        days : {name: 'days', label:'Días' , typeName: 'Number', flex: 1.5, optional: false},
        percentage : {name: 'percentage', label:'Porcentaje' , typeName: 'Number', flex: 1.5, optional: false},
    },
    sort: {
        field: 'paymentmethodtimeid',
        sort: 'asc'
    },
    keyComponent: 'PaymentmethodtimeDialog',
}

export default function PaymentmethodtimeDialog(props) {
    const {paymentmethodid} = props;
    const [state, setState] = React.useState({records: []});
    const {records} = state;

    useEffect(() =>{
        refresh()
    }, [paymentmethodid])

    const refresh = () => {
        setState({...state, records: []})
        if (paymentmethodid) {
            const filters = {
                entity : {name: 'paymentmethodtime'},
                fields: {
                    paymentmethodid : {
                        name: 'paymentmethodid',
                        operator: OPERATORS.OPERATOR_EQUAL,
                        value: paymentmethodid
                    }
                },
            }
            searchByCriteria(filters, result =>{
                if (result) {
                    setState({...state, records: result})
                }
            });
        }
    }
    
    const onDelete = (paymentmethodtime) => {
        doDelete('rest/paymentmethodtime/delete/' + paymentmethodtime.paymentmethodtimeid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const {records} = state;
        const record = records.filter(r => r.paymentmethodtimeid === e.paymentmethodtimeid);
        if (record.length) {
            doPutRest('paymentmethodtime', e, () => refresh());
        } else {
            delete e.paymentmethodtimeid
            doPutRest('paymentmethodtime', e, () => refresh());
        }
    }

    const addRecord = () => {
        const {records, detailsForm} = state;
        let newRecord = {paymentmethodtimeid: 0, paymentmethodid: paymentmethodid};
        records.push(newRecord)
        setState({...state, records: [...records]})
    }

    return(
        <Box sx={{m: 2, alignItems: 'left', width: '100%' }}>
            <Button size={'small'} variant="contained" onClick={addRecord} startIcon={<AddIcon />} sx={{m: 1}}>
                Añadir línea
            </Button>
            <GridTable ui={ui} onDelete={onDelete}
                       editMode={GridEditModes.Row}
                       records={records} rowId={'paymentmethodtimeid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '20vh', bgcolor: '#ffffff'}}
            />
        </Box>
    )
}
