import * as React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useEffect} from "react";
import uiDefinition from "../../Utils/uiDefinition";

import DragDropPhoto from "../DragDropFiles/DragDropPhoto";
import Checkbox from '@mui/joy/Checkbox'
import {generateRandomKey, groupBy} from "../../Utils/UtilsCommon";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTimePicker} from "@mui/x-date-pickers";
import { esES } from '@mui/x-date-pickers/locales';
import Box from "@mui/material/Box";
import {MobileTimePicker} from "@mui/lab";
import SingleSelectFormv2 from "./SingleSelectFormv2";
import MultipleSelectFormv2 from "./MultipleSelectFormv2";
import AddIcon from "@mui/icons-material/Add";
import NewObjectDialog from "./CrudForm/NewObjectDialog";
import Button from "@mui/material/Button";
import {appStyles} from "../../styles";
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import dayjs from "dayjs";
import 'dayjs/locale/es'
import CustomDialog from "../CustomDialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";



export default function SingleRecordForm(props) {
    const classes = appStyles();
    const {onChange, disableAutoFocus, records, ui, sx} = props;
    const [fields, setFields] = React.useState(null);
    const [recordToShow, setRecordsToShow] = React.useState({});
    const [valuesRequired, setValuesRequired] = React.useState([]);
    const [entityDefinition, setEntityDefinition] = React.useState(null);
    const [openNewObject, setOpenNewObject] = React.useState(false);
    const [newObjectAdded, setNewAddObject] = React.useState(false);

    const handledChange = (e, v) =>{
        let newRecord ={...recordToShow};
        const {onChangeHandleTrigger} = ui;

        if (!e && v.action) {
            delete newRecord[v.name]
        } else {
            const field = v?.name || e?.target?.name;
            const value = e?.target?.value || e[field];
            newRecord[field] = value ? value : null;
        }
        const field = v?.name || e?.target?.name;
        const value = e?.target?.value || e[field];
        onChangeHandleTrigger && !(value instanceof Date) ? onChangeHandleTrigger(newRecord, field, value, result => result ? checkRequireds(result, field, value) : checkRequireds(newRecord,field, value)) : checkRequireds(newRecord, field, value);
    }

    const checkRequireds = (newRecord, field, value) => {
        const fieldEmpty = valuesRequired ? valuesRequired.filter(v => newRecord[v] !== 0 && (!newRecord[v] || newRecord[v].length === 0)).length : 0 ;
        onChange(newRecord, fieldEmpty === 0, field, value);
    }

    const refresh = (records) => {
        checkValuesRequired(ui.fields)
        setFields(ui.fields)
        setRecordsToShow({...records})
    }

    useEffect(() =>{
        refresh(records);
    }, [records])

    const defaultDate = (field) =>{
        let alreadyDefined = Object.keys(recordToShow).includes(field.name);
        const date = field.typeName === 'Date' ? (field.endDay ? new Date().setHours(23, 59, 59, 999) : new Date().setHours(0,0,0,0)) : new Date();
        if (!alreadyDefined) {
            handledChange({target : {name: field.name, value: date}})
        }
        return date;
    }

    const checkValuesRequired = (newFields) => {
        if (newFields && (!valuesRequired || valuesRequired.length === 0)){
            let newValuesRequired = [];
            Object.keys(newFields).forEach( (key, i) => {
                const field = newFields[key];
                const name = field.name;
                if (field && name) {
                    if (!field.optional) {
                        const exist = valuesRequired ? valuesRequired.indexOf(name) : -1;
                        if (exist === -1) {
                            newValuesRequired = [...newValuesRequired, name];
                        }
                    }
                }
            });
            setValuesRequired(newValuesRequired);
        }
    }

    const handleAddOnClick = (addObject) => {
        setEntityDefinition(addObject);
        setOpenNewObject(true);
    }

    const toRender = () => {
        let allFields = Object.keys(fields).map(k => fields[k]);
        let grouped = groupBy(allFields, "group");
        const toReturn = Object.keys(grouped).map((k, index) => {
            let records = grouped[k].map((field, i) => {
                const name = field.name;
                if (field && name) {
                    switch (field.typeName){
                        case 'java.lang.Integer':
                        case 'java.lang.Double':
                        case 'Number':
                        case 'String':
                        case 'Password':
                            let textValue = props.records[name] === 0 ? 0 : (props.records[name] || '');
                            return(
                                <Grid key={name + i} item md={field.size? field.size : 6} >
                                    <Box bgcolor="background.default">
                                        <TextField
                                            value={textValue}
                                            onChange={handledChange}
                                            autoComplete={name}
                                            name={name} error={!field.optional && !textValue}
                                            helperText={field.helperText}
                                            type={field.typeName === 'Number'? 'number' : (field.typeName === 'Password' ? 'password' : '')}
                                            placeholder={field.placeholder || ''}
                                            required={!field.optional}
                                            multiline={field.multiline || false}
                                            rows={field.rows || 1}
                                            fullWidth={field.fullWidth? field.fullWidth : true}
                                            disabled={field.editable === false}
                                            id={name}
                                            label={field.label? field.label : ''}
                                            autoFocus={field.autofocus || (i === 0 && !disableAutoFocus)}
                                            key={name + i}
                                            size={'small'}
                                        />
                                    </Box>
                                </Grid>)
                        case 'Date' :
                            let defaultValue = props.records[name] || (field.empty ? '' : defaultDate(field));
                            const value = dayjs(defaultValue) || null;
                            return(
                                <Grid key={name + i} item md={field.size ? field.size : 6}>
                                    <Box bgcolor='background.default' maxWidth >
                                        <LocalizationProvider key={name + i + 'location'} dateAdapter={AdapterDayjs} adapterLocale="es"
                                                              localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                                            <DatePicker
                                                sx={{width: '100%'}}
                                                slotProps={{
                                                    textField: {
                                                        error: !defaultValue && !field.optional,
                                                        size: 'small'
                                                    },
                                                }}
                                                label={(field.label? field.label : 'Fecha') + (!field.optional? ' *': '')}
                                                value={value}
                                                onChange={(newValue) => handledChange({target : {name: field.name, value:newValue}})}
                                                renderInput={(params) => <TextField  size={'small'} {...params} fullWidth/>}
                                                key={name + i}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>)
                        case 'Datetime' :
                            let datetimeValue = props.records[name] || (field.empty ? '' : defaultDate(field));
                            return(
                                <Grid key={name + i} item md={field.size ? field.size : 6}>
                                    <Box bgcolor="background.default" maxWidth>
                                        <LocalizationProvider key={name + i + 'location'} dateAdapter={AdapterDayjs} adapterLocale="es">
                                            <DateTimePicker
                                                sx={{width: '100%'}}
                                                label={(field.label? field.label : 'Fecha') + (!field.optional? ' *': '')}
                                                value={dayjs(datetimeValue) || null}
                                                slotProps={{
                                                    textField: {
                                                        error: !datetimeValue && !field.optional,
                                                        size: 'small'
                                                    },
                                                }}
                                                key={name + i}
                                                onChange={(newValue) => handledChange({target : {name: field.name, value:newValue}})}
                                                renderInput={(params) => <TextField {...params} fullWidth/>}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>)
                        case 'Time' :
                            return (
                                <Grid key={name + i} item md={field.size ? field.size : 6}>
                                    <Box bgcolor="background.default">
                                        <LocalizationProvider key={name + i + 'location'} dateAdapter={AdapterDayjs} adapterLocale="es">
                                            <MobileTimePicker defaultValue={null} />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>)
                        case 'DragAndDrop' :
                            return(
                                <Grid key={name + i} item md={field.size ? field.size : 6}>
                                    <Box bgcolor="background.default">
                                        <DragDropPhoto key={name + i} onLoadFile={handledChange} name={name} file={props.records[name]}/>
                                    </Box>
                                </Grid>)
                        case 'Espace' :
                            return(
                                <Grid key={name + i} item md={field.size ? field.size : 6}></Grid>)
                        case 'Enum' :
                            return(
                                <Grid key={name + i} item md={field.size ? field.size : 6}>
                                    {<SingleSelectFormv2 name={name} groupBy={field.groupBy} helperText={field.helperText}
                                                         defaultValue={props.records[name]} typeName={name} optional={field.optional}
                                                         urlRecords={field.urlRecords} records={field.valueOptions}
                                                         label={field.label + (field.optional ? '' : ' *')} onChange={handledChange}/>}
                                </Grid>)
                        case 'Boolean' :
                        case 'java.lang.Boolean' :
                            return(
                                <Grid align={field.align} key={generateRandomKey()} item md={field.size? field.size : 6}>
                                    <Checkbox sx={{ml: 1}} size={'md'} key={name + i} name={name} defaultChecked={props.records[name]? props.records[name] : false}
                                              onChange={(e) => handledChange({target : {name: field.name, value:e.target.checked}})}  label={field.label}/>
                                </Grid>)
                        default:
                            if (uiDefinition[field.typeName]) {
                                return(
                                    <Grid key={name + i} item md={field.size? field.size : 6}>
                                        {field.isMulti ?
                                            <Grid container>
                                                <Grid item md={field.addObject ? 11 : 12}>
                                                    <MultipleSelectFormv2 name={name}
                                                                          defaultValue={props.records[name]} typeName={field.typeName}
                                                                          urlRecords={field.urlRecords} filterRecords={field.filterRecords}
                                                                          label={field.label + (field.optional ? '' : ' *')} onChange={handledChange}
                                                                          optional={field.optional}
                                                                          addObject={field.addObject &&
                                                                              <Grid item md={1} display={'flex'} justifyContent={'center'}>
                                                                                  <Button size={'small'} variant={'outlined'} color={'primary'} sx={{backgroundColor: '#ffffff',}} onClick={() => handleAddOnClick(field.addObject)}>
                                                                                      <AddIcon/>
                                                                                  </Button>
                                                                              </Grid>}
                                                    />
                                                </Grid>
                                            </Grid>
                                            :
                                            <Stack spacing={{ xs: 0.1, sm: 0.1}} direction="row" justifyContent="space-between" >
                                                <Box sx={{width: '100%'}}>
                                                    <SingleSelectFormv2 name={name} records={field.records} disabled={field.disabled} groupBy={field.groupBy} helperText={field.helperText} helperColor={field.helperColor}
                                                                        defaultValue={props.records[name]} typeName={field.typeName} criteriaFilter={field.criteriaFilter} optional={field.optional}
                                                                        urlRecords={field.urlRecords} filterRecords={field.filterRecords ? (callback) => field.filterRecords(props.records, callback) : null}
                                                                        label={field.label + (field.optional ? '' : ' *')} onChange={handledChange} newObjectAdded={newObjectAdded} setNewAddObject={setNewAddObject}
                                                                        addObject={field.addObject &&
                                                                            <Button size={'small'} variant={'outlined'} color={'primary'} sx={{backgroundColor: '#ffffff',}} onClick={() => handleAddOnClick(field.addObject)}>
                                                                                <AddIcon/>
                                                                            </Button>
                                                                        }
                                                    />
                                                </Box>
                                            </Stack>
                                        }
                                    </Grid>
                                )
                            } else {
                                let textValue = props.records[name];
                                return (
                                    <Grid key={name + i} item md={field.size ? field.size : 6}>
                                        <Box bgcolor="background.default">
                                            <TextField
                                                error={!field.optional && !textValue}
                                                key={name + i}
                                                value={textValue}
                                                onChange={handledChange}
                                                autoComplete= {name}
                                                name={name}
                                                type={field.typeName? field.typeName.toLowerCase() : ''}
                                                placeholder={field.placeholder || ''}
                                                required={!field.optional}
                                                fullWidth={field.fullWidth? field.fullWidth : true}
                                                disabled={field.editable === false}
                                                id={name}
                                                label={field.label? field.label : ''}
                                                autoFocus={i===0}
                                                size={'small'}
                                            />
                                        </Box>
                                    </Grid>)
                            }
                    }
                }
            });
            return (
                    <Grid item md={12} sx={{height: '100%', width: '100%'}} align={'center'}>
                        {k && k.length ?
                            <Accordion sx={{bgcolor: '#ffffff'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id={"panel1-header" + index}
                                >
                                    <Typography component="h4" variant="h7">
                                        {k}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid sx={{...sx}} container spacing={1.25} alignItems={'center'}>
                                        {records}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion> :
                            <Grid sx={{...sx}} container spacing={1.25} alignItems={'center'}>
                                {records}
                            </Grid>
                        }
                    </Grid>
            )
        })
        return toReturn;
    }

    const handleNewObject = (newObject) => {
        setNewAddObject(newObject);
    }

    return (
            <Grid sx={{...sx}} container spacing={1.25} alignItems={'center'}>
                {entityDefinition &&
                <CustomDialog title={'Nuevo ' + entityDefinition?.entity?.label?.toLowerCase()}
                    fullWidth={entityDefinition.onClickNewObject}
                    maxWidth={entityDefinition.onClickNewObject? "xl" : null}
                    onClose={() => setOpenNewObject(false)} open={openNewObject} disableEscapeKeyDown={false}>
                    <Box>
                        {entityDefinition.onClickNewObject ?
                            <Box>
                                {entityDefinition.onClickNewObject.component(setOpenNewObject, openNewObject)}
                            </Box>
                            :
                            <NewObjectDialog setOpen={setOpenNewObject} handleNewObject={handleNewObject} open={openNewObject} entityDefinition={entityDefinition}/>
                        }
                    </Box>
                </CustomDialog>}
                {fields && recordToShow &&  toRender()}
            </Grid>
    );

}
