import * as React from "react";
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import uiDefinition from "../../Utils/uiDefinition";
import MailIcon from "@mui/icons-material/Mail";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {ProgressBar} from "../ProgressBar";
import Stack from "@mui/material/Stack";
import DownloadIcon from '@mui/icons-material/Download';
import MenuPrintIconButton from "../Button/MenuPrintIconButton";
import {getEntityDescription, isObject} from "../../Utils/UtilsCommon";
import Tooltip from "@mui/material/Tooltip";
import SingleSelectTable from "./SingleSelectTable";


export const getGridTableUI = (props) => {
    const {onEdit, onDelete,sendEmail, actionFlex, onPrint} = props;
    const fields = getFields(props);
    if (onEdit || onDelete || sendEmail || actionFlex || onPrint) {
        const newFields = {columns: [...fields, {...getActions(props)}]};
        newFields.columns.forEach(c => {
            c.minWidth = c.flex * 140;
        })
        return(props?.ui?.fields? newFields :  {columns: []})
    } else {
        const newFields = {columns: [...fields]};
        return(props?.ui?.fields? newFields :  {columns: []})
    }
}

export const defaultSendMail = (entity, emailField, setOpenMail, setMailRecords) =>{
    const to = entity[emailField];
    setMailRecords({to: to})
    setOpenMail(true);
}

const getActions = (props) => {
    const {onEdit, onDelete, sendEmail, downloadData, actionFlex, onPrint, printOptions, pdfSet} = props;
    const actionsSize = actionFlex || 0.7;
    return {field: 'actions', type: 'actions', headerName: 'Acciones', flex: actionsSize, printable: false, headerAlign: 'center', align: 'center',  headerClassName: 'super-app-theme--header',
        renderCell: (e) =>
            <Box justifyContent={'center'} justifyItems={'center'} sx={{m: -2}}>
                <Stack direction="row"
                       spacing={0}
                       justifyContent="center"
                       alignItems="center">
                {onEdit &&
                    <IconButton color={'warning'} value={e.row} onClick={() => onEdit(e.row)} variant="contained" size="small">
                        <OpenInNewIcon/>
                    </IconButton>}
                {onPrint &&
                    <MenuPrintIconButton row={e.row} options={printOptions} onClickOption={(op) => onPrint.onSelectedOption(op, e.row, pdfSet)} />
                }
                {sendEmail &&
                    <IconButton size={'small'} color={'primary'} value={e.row} onClick={() => sendEmail(e.row)} variant="contained">
                        <MailIcon/>
                    </IconButton>
                }
                {downloadData &&
                    <IconButton color={'info'} value={e.row} onClick={() => downloadData(e.row)} variant="contained" size="small">
                        <DownloadIcon/>
                    </IconButton>
                }
                {onDelete &&
                    <IconButton color={'error'} value={e.row} onClick={() => onDelete(e.row)} variant="contained" size="small">
                        <DeleteIcon/>
                    </IconButton>
                }
                </Stack>
            </Box>
    }
}

const getFields = (props) => {
    const {onChange, ui, handleSelectEntity, gridRef, rowId} = props;
    const fields = ui.fields;

    const newFields = fields && Object.keys(fields).map(key => {
        const field = fields[key];
        if(field?.name){
            switch (field.typeName){
                case 'String' :
                    return {field: field.name, headerName: field.label, flex: field.flex? field.flex : 1,
                        headerAlign: field.headerAlign? field.headerAlign : 'center', align: field.align || 'center', editable: field.editable === false ? field.editable : onChange,
                        hide: field.visible === false ? !field.visible : false,  headerClassName: 'super-app-theme--header',
                        preProcessEditCellProps: (params) => {
                            const hasError = !params.props?.value?.length && !field.optional;
                            return { ...params.props, error: hasError };
                        }
                    }
                case 'Number' :
                    return {field: field.name, type: 'number',headerName: field.label, flex: field.flex? field.flex : 1,
                        editable: field.editable === false ? field.editable : onChange,  align: field.align? field.align : 'center',
                        hide: field.visible === false ? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center',  headerClassName: 'super-app-theme--header',
                        preProcessEditCellProps: (params) => {
                            let value = params.props.value;
                            const hasError = !value && value !== 0  && !field.optional;
                            return { ...params.props, error: hasError };
                        },
                        valueFormatter: (value) => field.valueFormatter ? field.valueFormatter(value) : value,
                    }
                case 'Function' :
                    return (
                        {field: field.name, headerName: field.label, align: field.align? field.align : 'center', flex: field.flex? field.flex : 1, sortable: false, filterable: false,
                            hide: field.visible === false? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center', headerClassName: 'super-app-theme--header',
                            renderCell: (v, row, column, apiRef) => field.func(v)}
                    )
                case 'Hour' :
                    return (
                        { field: field.name,
                            headerName: field.label,
                            align: field.align ? field.align : 'center',
                            flex: field.flex ? field.flex : 1,
                            sortable: false,
                            filterable: false,
                            editable: field.editable,
                            type:'dateTime',
                            hide: field.visible === false ? !field.visible : false,
                            headerAlign: field.headerAlign ? field.headerAlign : 'center',
                            headerClassName: 'super-app-theme--header',
                            valueGetter: (value, row, column, apiRef) => {
                                return value && (field.hourField ? new Date(value[field.hourField]) : new Date(value))
                            },
                            renderCell: (v, row, column, apiRef) => {
                                if (field.renderCell) {
                                    return field.renderCell(v, row, column, apiRef);
                                } else {
                                    if (v.value) {
                                        const inCell = new Date(v.value).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }).toString();
                                        const inTooltip = new Date(v.value).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).toString();
                                        return (
                                            <Tooltip title={inTooltip}>
                                                <span>{inCell}</span>
                                            </Tooltip>
                                        );
                                    }
                                    return '';
                                }
                            }
                        }
                    )
                case 'Select' :
                    return (
                        {field: field.name, type: 'singleSelect', valueOptions: field.valueOptions ? field.valueOptions : field.className ? [] : [],
                            headerName: field.label, flex: field.flex? field.flex : 1, editable: field.editable===false? field.editable : onChange,  align: field.align? field.align : 'center',
                            hide: field.visible===false ? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center',
                            headerClassName: 'super-app-theme--header'}
                    )
                case 'Date' :
                    return (
                        {field: field.name, headerName: field.label || 'Fecha', editable: field.editable, flex: field.flex ? field.flex : 1,
                            align: field.align || 'center', headerAlign: field.headerAlign || 'center',
                            hide: field.visible === false ? !field.visible : false, headerClassName: 'super-app-theme--header',
                            type: 'date',
                            valueGetter: (value, row, column, apiRef) => {
                                return value && new Date(value)
                            },
                        })
                case 'Datetime' :
                case 'Time' :
                    return (
                        {field: field.name, headerName: field.label || 'Fecha', editable: field.editable, type:'dateTime', flex: field.flex || 1,
                            align: field.align ? field.align : 'center',headerAlign: field.headerAlign ? field.headerAlign : 'center',
                            hide: field.visible === false ? !field.visible : false, headerClassName: 'super-app-theme--header',
                            valueGetter: (value, row, column, apiRef) => value && new Date(value),
                        })
                case 'Progress' :
                    return (
                        {
                            field: field.name,
                            headerName: field.label || '', headerAlign: field.headerAlign? field.headerAlign : 'center',
                            editable: false,
                            flex: field.flex ? field.flex : 1,
                            align: field.align? field.align : 'center',
                            hide: field.visible===false? !field.visible : false, headerClassName: 'super-app-theme--header',
                            renderCell: (v, row, column, apiRef) =>
                                    <Box sx={{width: '100vh'}}>
                                        <ProgressBar value={ v.row[field.name] || 0}/>
                                    </Box>
                        })
                case 'Checkbox' :
                case 'Boolean' :
                    return (
                        {field: field.name, headerName: field.label || '', editable: true ,flex: field.flex ? field.flex : 1, type: 'boolean',
                            hide: field.visible===false? !field.visible : false, headerAlign: field.headerAlign? field.headerAlign : 'center',
                            headerClassName: 'super-app-theme--header'})
                default:
                     if (uiDefinition[field.typeName]) {
                         return (
                             {  field: field.name, headerName: field.label, flex: field.flex? field.flex : 1, editable: true,
                                 align: field.align ? field.align : 'center', headerAlign: field.headerAlign? field.headerAlign : 'center',
                                 hide: field.visible === false ? !field.visible : false, headerClassName: 'super-app-theme--header',
                                 valueGetter: (value, row, column, apiRef) => value,
                                 valueFormatter: (value) => value ? getEntityDescription(value, field.typeName, true, field.keyField, field.descriptiveField) : '',
                                 preProcessEditCellProps: (params) => {
                                     let value = params.props.value;
                                     let hasError = !value && value !== 0  && !field.optional;
                                     if (!isObject(value)) {
                                         value = params.row[field.name];
                                         hasError = true;
                                     }
                                     return { ...params.props, error: hasError, value };
                                 },
                                 renderCell: (value, row, column, apiRef) =>
                                     <Box display="flex" justifyContent={field.align} alignItems="center" sx={{height: '100%', width: '100%'}}>
                                         {value ? getEntityDescription(value?.row[value?.field], field.typeName, field.hideId, field.keyField, field.descriptiveField) : ''}
                                    </Box>,
                                 renderEditCell : (value) =>
                                     <Box display="flex" justifyContent={field.align} alignItems="center" sx={{height: '100%', width: '100%'}}>
                                         <SingleSelectTable name={field.name} typeName={field.typeName} defaultValue={value.row[value.field]} optional={field.optional}
                                                            label={field.label} row={value.row} onChange={(newRow, oldRow, refresh) => {
                                                                value.row[value.field] = newRow[field.name];
                                                                gridRef.current.setEditCellValue({id: newRow[rowId], field: field.name, value: newRow[field.name]});
                                                                requestAnimationFrame(() => {
                                                                     field.proccesCellUpdate &&  field.proccesCellUpdate(value, newRow, oldRow, gridRef, field, rowId)
                                                                 });
                                                            }}/>
                                     </Box>
                             }
                         )
                     }
            }
        }
    });
    return (fields ? newFields : [])
}
